import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore, useSuperHubStore } from '@core/store';

import useBuildVersionPath from '@routes/SuperHub/Versions/hooks/useBuildVersionPath';

import Badge from '@ui/Badge';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';
import Tooltip from '@ui/Tooltip';
import Truncate from '@ui/Truncate';

import styles from './index.module.scss';

function VersionDropdown() {
  const bem = useClassy(styles, 'VersionDropdown');
  const [currentVersion, allVersions, isSuperHubAdmin] = useSuperHubStore(s => [
    s.versions.currentVersion,
    s.versions.data.data,
    s.isSuperHubAdmin,
  ]);
  const versionVisibility = useProjectStore(s => s.data.appearance.navigation.version);
  const buildVersionPath = useBuildVersionPath();

  const versions = useMemo(() => {
    if (isSuperHubAdmin) {
      return allVersions;
    }
    return allVersions.filter(version => version.privacy.view !== 'hidden');
  }, [isSuperHubAdmin, allVersions]);

  // Render nothing if for some reason no current version exists.
  if (!currentVersion) return null;

  // Render nothing if versions are configured to be hidden in the subnav.
  if (versionVisibility === 'disabled') return null;

  const currentVersionLabel = currentVersion?.display_name || `v${currentVersion.name}`;

  // When only a single version exists, render a static label.
  if (versions.length <= 1) {
    return (
      <Truncate className={bem('&', '_inactive', 'rm-Header-bottom-link')} maxWidth="150px">
        {currentVersionLabel}
      </Truncate>
    );
  }

  return (
    <Dropdown className="rm-VersionDropdown" justify="start">
      <Button bem={{ slate_text: true }} className={bem('&', 'rm-Header-bottom-link')}>
        <Truncate maxWidth="150px">{currentVersionLabel}</Truncate>
        <Icon name="chevron-down" />
      </Button>
      <Menu className="rm-VersionDropdown-menu">
        {versions.map(version => {
          const versionLabel = version.display_name || `v${version.name}`;

          return (
            <MenuItem
              key={version.name}
              active={currentVersion.name === version.name}
              href={buildVersionPath(version.name)}
              TagName="a"
            >
              <Flex className={bem('-item')} gap="0" layout="col">
                <Flex align="center" className={bem('-item-content')} gap="xs">
                  <span className={bem('-item-label')}>{versionLabel}</span>
                  {version.privacy.view === 'default' && <Badge kind="success">Default</Badge>}
                  {version.state === 'deprecated' && <Badge kind="light">Deprecated</Badge>}
                  {version.release_stage === 'beta' && <Badge kind="light">Beta</Badge>}
                  {version.privacy.view === 'hidden' && (
                    <Tooltip content="Only visible to ReadMe admins" placement="bottom">
                      <Icon aria-label="Hidden Version" name="eye-off" />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            </MenuItem>
          );
        })}
      </Menu>
    </Dropdown>
  );
}

export default VersionDropdown;
